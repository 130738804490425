
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function productDataRT () {
    function scopeNameTerm1(collection, attribute, attributeIndex) {
        var name = attribute.name;
        var term = attribute.term;
        return _createElement('div', {
            'key': name,
            'className': 'cm_browse-coordinating'
        }, '\n    Browse coordinating\n    ', _createElement('a', {
            'href': this.getSearchLink({
                facetSelection: [{
                        field: 'Type',
                        term
                    }]
            }),
            'target': '_blank'
        }, '\n      ', name, ' ', _createElement('i', {
            'className': 'fa fa-caret-right',
            'aria-hidden': 'true'
        })));
    }
    function repeatAttribute2(collection, attribute, attributeIndex, name, term) {
        return scopeNameTerm1.apply(this, [
            collection,
            attribute,
            attributeIndex
        ]);
    }
    function scopeCollection3() {
        var collection = window.__CONVERMAX__?.product?.collection;
        return _createElement.apply(this, [
            'div',
            { 'className': 'fa-ul cm_coordinating-items' },
            _map([
                {
                    name: 'Rugs',
                    term: 'Rugs'
                },
                {
                    name: 'Pillows',
                    term: 'Pillows'
                },
                {
                    name: 'Home Decor',
                    term: 'Home-Accents'
                },
                {
                    name: 'Art',
                    term: 'Wall-Art'
                }
            ], repeatAttribute2.bind(this, collection)),
            collection ? _createElement('div', {
                'key': 'collection',
                'className': 'cm_browse-coordinating'
            }, '\n    Browse the\n    ', _createElement('a', {
                'href': collection.url,
                'target': '_blank'
            }, '\n      ', collection.name, ' collection\n      ', _createElement('i', {
                'className': 'fa fa-caret-right',
                'aria-hidden': 'true'
            }))) : null
        ]);
    }
    return scopeCollection3.apply(this, []);
}
        export const componentNames = []