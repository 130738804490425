
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function CollectionPageRT () {
    function mergeProps(inline, external) {
        var res = Object.assign({}, inline, external);
        if (inline.hasOwnProperty('style')) {
            res.style = _.defaults(res.style, inline.style);
        }
        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
            res.className = external.className + ' ' + inline.className;
        }
        return res;
    }
    return _createElement(_Fragment, {}, [this.FacetPanel(function () {
            return _createElement('div', { 'className': 'cm_FacetPanel' }, [[this.filterChips(function () {
                        function repeatChips1(chips, chipsIndex) {
                            return [chips(function () {
                                    function repeatI1_reviewChip(i, iIndex) {
                                        return _createElement('span', {
                                            'key': `${ this.field }|${ i }`,
                                            'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                        });
                                    }
                                    function scopeDollarInchMm1_rangeChip() {
                                        var dollar = [
                                            'price',
                                            'Price'
                                        ].includes(this.field) ? '$' : '';
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '484' }, dollar, this.selectedRange[0], inch, mm, ' to\n    ', dollar, this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '630' }, dollar, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '738' }, dollar, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                                'className': 'cm_icon cm_icon-times',
                                                'height': '20px',
                                                'role': 'image',
                                                'viewBox': '0 0 22 22',
                                                'key': '8760'
                                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                    }
                                    return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'image',
                                            'viewBox': '0 0 22 22',
                                            'key': '2570'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                        'a',
                                        {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        },
                                        _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI1_reviewChip.bind(this)),
                                        this.value !== '5' ? _createElement('span', { 'key': '391' }, ' and up') : null
                                    ]), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'image',
                                            'viewBox': '0 0 22 22',
                                            'key': '4900'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeDollarInchMm1_rangeChip.apply(this, []) : this.template === 'colorChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_colorChip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), '\n  ', this.value, '\n  ', _createElement('span', { 'className': 'cm_chip-color' }, this.colorTile, ' ', this.percent, '%')), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'image',
                                            'viewBox': '0 0 22 22',
                                            'key': '2640'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                            'className': 'facettitle',
                            'tabIndex': '0'
                        }, 'Your search:'), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                            'div',
                            {
                                'role': 'list',
                                'className': 'cmRepeater_chips'
                            },
                            _map(this.chips, repeatChips1.bind(this))
                        ]), _createElement('div', {
                            'className': 'button btn btn-default cm_filter-chips_button',
                            'data-cm-role': 'start-over'
                        }, '\n        Clear all\n      '))));
                    }, {
                        widgetName: 'filter-chips',
                        items: undefined
                    })]], [this.facets(function () {
                    function repeatFacets1(facets, facetsIndex) {
                        return [facets(function () {
                                function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass + ' with-checkbox' }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'custom cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues2(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass + ' with-checkbox' }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'custom cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatShowAlwaysValues3(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            function scopeStarFill1(i, iIndex) {
                                                var starFill = this.value - i;
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function repeatI2(i, iIndex, starFill) {
                                                return scopeStarFill1.apply(this, [
                                                    i,
                                                    iIndex
                                                ]);
                                            }
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass + ' with-checkbox' }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'custom cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement.apply(this, [
                                                'span',
                                                { 'className': 'cm_review-stars' },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI2.bind(this))
                                            ]), this.value !== '5' ? _createElement('span', {
                                                'className': 'cm_and-up',
                                                'key': '518'
                                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues4(Values, ValuesIndex) {
                                    return [Values(function () {
                                            function scopeStarFill1(i, iIndex) {
                                                var starFill = this.value - i;
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function repeatI2(i, iIndex, starFill) {
                                                return scopeStarFill1.apply(this, [
                                                    i,
                                                    iIndex
                                                ]);
                                            }
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass + ' with-checkbox' }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'custom cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement.apply(this, [
                                                'span',
                                                { 'className': 'cm_review-stars' },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI2.bind(this))
                                            ]), this.value !== '5' ? _createElement('span', {
                                                'className': 'cm_and-up',
                                                'key': '518'
                                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues5(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass + ' with-checkbox' }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'custom cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), !this.isCustomRange ? [
                                                _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '1731'
                                                }, _createElement('span', {}, this.value)),
                                                _createElement('div', {
                                                    'className': 'facetentryval',
                                                    'key': '1733'
                                                }, this.hitCount)
                                            ] : null, this.isCustomRange ? [_createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '4091'
                                                }, this.finite ? _createElement('span', { 'key': '522' }, '$', this.range[0], ' - $', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '611' }, '$', this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '687' }, '$', this.range[1], ' and less') : null)] : null);
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                                    'className': 'facettitle',
                                    'data-cm-role': 'toggle-facet',
                                    'tabIndex': '0'
                                }, '\n      ', this.name, '\n      ', [this.tooltip(function () {
                                        return _createElement('span', { 'className': 'cm_tooltip' }, _createElement('i', {
                                            'className': 'fa fa-question-circle-o',
                                            'aria-hidden': true
                                        }));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], this.isCollapsed ? [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-plus-minus',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 448 512',
                                        'key': '4640'
                                    }, _createElement('path', { 'd': 'M416,208H272V64c0-17.67-14.33-32-32-32h-32c-17.67,0-32,14.33-32,32v144H32c-17.67,0-32,14.33-32,32v32c0,17.67,14.33,32,32,32h144v144c0,17.67,14.33,32,32,32h32c17.67,0,32-14.33,32-32V304h144c17.67,0,32-14.33,32-32v-32c0-17.67-14.33-32-32-32z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-plus-minus',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 448 512',
                                        'key': '8680'
                                    }, _createElement('path', { 'd': 'M416,208H32c-17.67,0-32,14.33-32,32v32c0,17.67,14.33,32,32,32h384c17.67,0,32-14.33,32-32v-32c0-17.67-14.33-32-32-32z' }))] : null), _createElement('div', {
                                    'className': 'facetbody',
                                    'role': 'list'
                                }, this.template === 'simpleFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_simpleFacet',
                                    'key': '1203'
                                }, this.showFilterInput ? _createElement('div', {
                                    'className': 'filter-input',
                                    'key': '1289'
                                }, [this.filterInput(function () {
                                        return _createElement('div', {
                                            'className': 'input  cm_filterInput',
                                            'placeholder': 'Enter'
                                        });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], this.inputNotEmpty ? _createElement('span', {
                                    'className': 'filter-input_clear-container',
                                    'onClick': this.clearInput,
                                    'key': '1609'
                                }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                        'className': 'facetdiv',
                                        'key': '17781'
                                    }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_ShowAlwaysValues' },
                                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                ]), _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_Values' },
                                    _map(this.Values, repeatValues2.bind(this))
                                ]), this.needShowMore ? _createElement('div', {
                                    'className': 'facetdiv cm_show-all-container',
                                    'key': '3677'
                                }, _createElement('a', {
                                    'className': 'cm_show-all',
                                    'data-cm-role': 'toggle-show-more',
                                    'tabIndex': '0'
                                }, this.moreValuesToShow ? ['Show all'] : null, !this.moreValuesToShow ? ['Show only top'] : null)) : null)) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_reviewFacet',
                                    'key': '4006'
                                }, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_ShowAlwaysValues' },
                                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues3.bind(this))
                                ]), _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_Values' },
                                    _map(this.Values, repeatValues4.bind(this))
                                ])) : null, this.template === 'priceFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_priceFacet',
                                    'key': '7193'
                                }, !this.ranges.length ? [
                                    _createElement('div', { 'key': '72771' }, '$', this.selectedRange[0], ' to $', this.selectedRange[1]),
                                    '\n  ',
                                    this.slider,
                                    '\n  ',
                                    _createElement('div', {
                                        'className': 'cm_flex',
                                        'key': '72773'
                                    }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: $', this.min), _createElement('div', {}, 'Max: $', this.max))
                                ] : null, this.ranges.length ? [_createElement.apply(this, [
                                        'div',
                                        {
                                            'className': 'cmRepeater_Values',
                                            'key': '75531'
                                        },
                                        _map(this.Values, repeatValues5.bind(this))
                                    ])] : null, [this.Inputs(function () {
                                        return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                return _createElement('div', {
                                                    'placeholder': 'Min',
                                                    'className': 'cm_inputMin'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], _createElement('span', { 'className': 'separator' }, 'to'), [this.inputMax(function () {
                                                return _createElement('div', {
                                                    'placeholder': 'Max',
                                                    'className': 'cm_inputMax'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], _createElement('button', {
                                            'type': 'button',
                                            'className': 'button btn secondary',
                                            'onClick': this.setCustomRange
                                        }, '\n    GO\n  '));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]) : null, this.template === 'thicknessFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_thicknessFacet',
                                    'key': '10208'
                                }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', this.selectedRange[0], '" to ', this.selectedRange[1], '"\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min), _createElement('div', { 'className': 'max' }, this.max), _createElement('div', { 'className': 'clear' })))) : null, this.template === 'advancedColorFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_advancedColorFacet',
                                    'key': '10623'
                                }, _createElement('div', { 'className': 'base-palette' }, '\n  ', this.paletteItems, '\n  ', _createElement('div', { 'className': 'clear' }), _createElement('div', {
                                    'className': 'cm_button',
                                    'onClick': this.openSimplePaletteDialog
                                }, _createElement('i', {
                                    'className': 'fa fa-picture-o',
                                    'aria-hidden': 'true'
                                }), '\n    Pick your colors\n  '), _createElement('div', {
                                    'className': 'cm_button',
                                    'onClick': this.openColorExtractorDialog
                                }, _createElement('i', {
                                    'className': 'fa fa-cloud-upload',
                                    'aria-hidden': 'true'
                                }), '\n    Upload Your Color\n  '), _createElement('div', { 'className': 'clear' })), '\n', this.sliders, '\n') : null, this.template === 'sizeFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_sizeFacet',
                                    'key': '11159'
                                }, [this.WidthFacet(function () {
                                        return _createElement('div', { 'className': 'cm_WidthFacet' }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, _createElement('span', { 'className': 'subsize-title' }, this.name, ': '), !this.isNullRange ? _createElement('span', { 'key': '142' }, this.selectedRange[0], ' - ', this.selectedRange[1]) : null, this.isNullRange ? _createElement('span', { 'key': '235' }, this.selectedRange[0]) : null), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min), _createElement('div', { 'className': 'max' }, this.max), _createElement('div', { 'className': 'clear' }))));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], [this.LengthFacet(function () {
                                        return _createElement('div', { 'className': 'cm_LengthFacet' }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, _createElement('span', { 'className': 'subsize-title' }, this.name, ': '), !this.isNullRange ? _createElement('span', { 'key': '143' }, this.selectedRange[0], ' - ', this.selectedRange[1]) : null, this.isNullRange ? _createElement('span', { 'key': '236' }, this.selectedRange[0]) : null), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min), _createElement('div', { 'className': 'max' }, this.max), _createElement('div', { 'className': 'clear' }))));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]) : null));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_facets' },
                        _map(this.facets, repeatFacets1.bind(this))
                    ]));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('div', { 'className': 'cm_main-content' }, _createElement('header', { 'className': 'section-header' }, _createElement('h1', mergeProps({ 'className': 'section-header--title' }, { dangerouslySetInnerHTML: { __html: Convermax.header.title } })), _createElement('div', mergeProps({ 'className': 'rte rte--header' }, { dangerouslySetInnerHTML: { __html: Convermax.header.content } }))), _createElement('hr', { 'className': 'hr--offset-left' }), [_createElement('div', {
            'id': 'itemsBlock',
            'key': '251460'
        }, [this.message(function () {
                return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                        'href': 'mailto:support@convermax.com',
                        'className': 'cm_message-link'
                    }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                        'className': 'cm_message__incompatible',
                        'key': '4950'
                    }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                        'className': 'cm_message__universal',
                        'key': '7690'
                    }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'href': this.searchByVehicleUrl
                    }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                    'className': 'cmTemplate_badRequest',
                    'key': '1136'
                }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver
                }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                    'className': 'cmTemplate_corrected',
                    'key': '1554'
                }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                    'className': 'cmTemplate_networkError',
                    'key': '1816'
                }, 'Network error\n', this.error ? [
                    '(id: ',
                    this.error.userId,
                    ', timestamp: ',
                    this.error.timestamp,
                    ', instance: ',
                    this.error.instance,
                    ')'
                ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                    'className': 'cmTemplate_nothing',
                    'key': '2108'
                }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                    '\n    This ',
                    this.pageType,
                    ' doesn\'t have any\n    ',
                    !!this.vehicleString ? [
                        ' parts that fit ',
                        _createElement('span', { 'key': '23371' }, '\'', this.vehicleString, '\'.')
                    ] : null,
                    !this.vehicleString ? [' products.'] : null
                ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '25411' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString ? [
                    '\n    Press ',
                    _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver,
                        'key': '26721'
                    }, 'here'),
                    ' to clear\n    ',
                    !this.vehicleString ? [' selection. '] : null,
                    this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
                ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                    'className': 'cmTemplate_timeout',
                    'key': '3065'
                }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.retry
                }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                    'className': 'cmTemplate_partial',
                    'key': '3280'
                }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                    'className': 'cmTemplate_unknown',
                    'key': '3509'
                }, 'We failed to process your request', this.error ? [
                    '\n  (',
                    this.error.status ? [
                        _createElement('b', { 'key': '36550' }, 'status:'),
                        ' ',
                        this.error.status,
                        ', '
                    ] : null,
                    this.error.userId ? [
                        _createElement('b', { 'key': '37420' }, 'id:'),
                        ' ',
                        this.error.userId,
                        ', '
                    ] : null,
                    this.error.timestamp ? [
                        _createElement('b', { 'key': '38250' }, 'timestamp:'),
                        ' ',
                        this.error.timestamp,
                        ', '
                    ] : null,
                    _createElement('b', { 'key': '36204' }, 'instance:'),
                    ' ',
                    this.error.instance,
                    ')'
                ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.retry
                }, 'try your search again'), '.\n') : null);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.customMessage(function () {
                return _createElement('div', { 'className': 'cm_message cm_customMessage' }, []);
            }, {
                widgetName: 'undefined',
                items: undefined
            })], [this.SearchHeader(function () {
                return _createElement('div', { 'className': 'cm_SearchHeader' }, [this.facetDialogButton(function () {
                        return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                                return _createElement('div', { 'className': 'btn btn-default cm_dialogButton' }, '\n  Filters \n    ', this.withSelection ? [_createElement('svg', {
                                        'className': 'cm_icon cm_filter-icon',
                                        'viewBox': '-5 0 394 394.00003',
                                        'key': '610'
                                    }, _createElement('path', { 'd': 'm367.82 0h-351.26c-6.1992-0.011719-11.879 3.4492-14.711 8.9609-2.8711 5.5859-2.3672 12.312 1.3008 17.414l128.69 181.29c0.042968 0.0625 0.089843 0.12109 0.13281 0.18359 4.6758 6.3125 7.207 13.961 7.2188 21.816v147.8c-0.027344 4.375 1.6914 8.582 4.7734 11.688 3.0859 3.1016 7.2812 4.8516 11.656 4.8516 2.2227-0.003906 4.4258-0.44531 6.4805-1.3008l72.312-27.57c6.4766-1.9805 10.777-8.0938 10.777-15.453v-120.02c0.011719-7.8555 2.543-15.504 7.2148-21.816 0.042968-0.0625 0.089844-0.12109 0.13281-0.18359l128.69-181.29c3.668-5.0977 4.1719-11.82 1.3008-17.406-2.8281-5.5156-8.5117-8.9766-14.707-8.9648z' }))] : null, !this.withSelection ? [_createElement('svg', {
                                        'className': 'cm_icon cm_filter-icon',
                                        'viewBox': '0 0 247.46 247.46',
                                        'key': '8020'
                                    }, _createElement('path', { 'd': 'm246.74 13.984c-1.238-2.626-3.881-4.301-6.784-4.301h-232.46c-2.903 0-5.545 1.675-6.784 4.301-1.238 2.626-0.85 5.73 0.997 7.97l89.361 108.38v99.94c0 2.595 1.341 5.005 3.545 6.373 1.208 0.749 2.579 1.127 3.955 1.127 1.137 0 2.278-0.259 3.33-0.78l50.208-24.885c2.551-1.264 4.165-3.863 4.169-6.71l0.098-75.062 89.366-108.39c1.848-2.239 2.237-5.344 0.999-7.969zm-103.65 108.89c-1.105 1.34-1.711 3.023-1.713 4.761l-0.096 73.103-35.213 17.453v-90.546c0-1.741-0.605-3.428-1.713-4.771l-80.958-98.191h200.65l-80.958 98.191z' }))] : null);
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })]);
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })], _createElement('div', {
                    'className': 'cm_total-hits',
                    'role': 'status'
                }, _createElement('b', {}, 'Products'), ' 1 – ', this.pageTo, ' of ', _createElement('b', {}, this.totalHits)), _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', { 'className': 'sort-label' }, 'Sort by: '), '\n  ', this.sortSelect, '\n'));
            }, {
                widgetName: 'search-header',
                items: undefined
            })], [this.SearchResult(function () {
                function repeatItems1(items, itemsIndex) {
                    return [items(function () {
                            function repeatI1(i, iIndex) {
                                return _createElement('span', {
                                    'key': i,
                                    'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                                });
                            }
                            function mergeProps(inline, external) {
                                var res = Object.assign({}, inline, external);
                                if (inline.hasOwnProperty('style')) {
                                    res.style = _.defaults(res.style, inline.style);
                                }
                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                    res.className = external.className + ' ' + inline.className;
                                }
                                return res;
                            }
                            function scopePrice3() {
                                var price = this.min_price.toLocaleString('en-US', { minimumFractionDigits: 2 });
                                return _createElement('div', {
                                    'className': 'cm_product-price',
                                    'key': '776'
                                }, '\n    From $', price.split('.')[0], _createElement('sup', {}, price.split('.')[1]));
                            }
                            return _createElement('a', {
                                'href': this.url,
                                'className': 'cm_product-item'
                            }, _createElement('div', { 'className': 'img-block' }, _createElement('img', {
                                'src': this.imageOrDefault(this.resizeImage(this.image)),
                                'alt': this.removeHTML(this.title),
                                'onError': this.onImageError
                            })), this.Badge ? _createElement('div', {
                                'className': 'cm_badge',
                                'key': '218'
                            }, this.Badge[0]) : null, [this.review_count ? _createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cm_review-stars',
                                        'key': '2830'
                                    },
                                    _map([
                                        0,
                                        1,
                                        2,
                                        3,
                                        4
                                    ], repeatI1.bind(this)),
                                    _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                                ]) : null], _createElement('div', mergeProps({ 'className': 'cm_product-title' }, { dangerouslySetInnerHTML: { __html: this.title } })), !isNaN(this.min_price) ? scopePrice3.apply(this, []) : null, this.on_sale ? _createElement('span', {
                                'className': 'sale-tag',
                                'key': '1038'
                            }, '\n    Save ', Math.round((this.compare_at_price - this.min_price) / this.compare_at_price * 100), '%\n  ') : null);
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_SearchResult' }, _createElement.apply(this, [
                    'div',
                    { 'className': (this.view ? ` ${ this.view }` : '') + ' cmRepeater_items' },
                    _map(this.items, repeatItems1.bind(this))
                ]), [this.message(function () {
                        return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                                'href': 'mailto:support@convermax.com',
                                'className': 'cm_message-link'
                            }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                                'className': 'cm_message__incompatible',
                                'key': '4950'
                            }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                                'className': 'cm_message__universal',
                                'key': '7690'
                            }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                                'tabIndex': '0',
                                'className': 'cm_message-link',
                                'href': this.searchByVehicleUrl
                            }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                            'className': 'cmTemplate_badRequest',
                            'key': '1136'
                        }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver
                        }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                            'className': 'cmTemplate_corrected',
                            'key': '1554'
                        }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                            'className': 'cmTemplate_networkError',
                            'key': '1816'
                        }, 'Network error\n', this.error ? [
                            '(id: ',
                            this.error.userId,
                            ', timestamp: ',
                            this.error.timestamp,
                            ', instance: ',
                            this.error.instance,
                            ')'
                        ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                            'className': 'cmTemplate_nothing',
                            'key': '2108'
                        }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                            '\n    This ',
                            this.pageType,
                            ' doesn\'t have any\n    ',
                            !!this.vehicleString ? [
                                ' parts that fit ',
                                _createElement('span', { 'key': '23371' }, '\'', this.vehicleString, '\'.')
                            ] : null,
                            !this.vehicleString ? [' products.'] : null
                        ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '25411' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString ? [
                            '\n    Press ',
                            _createElement('a', {
                                'tabIndex': '0',
                                'className': 'cm_message-link',
                                'onClick': this.startOver,
                                'key': '26721'
                            }, 'here'),
                            ' to clear\n    ',
                            !this.vehicleString ? [' selection. '] : null,
                            this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
                        ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                            'className': 'cmTemplate_timeout',
                            'key': '3065'
                        }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.retry
                        }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                            'className': 'cmTemplate_partial',
                            'key': '3280'
                        }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                            'className': 'cmTemplate_unknown',
                            'key': '3509'
                        }, 'We failed to process your request', this.error ? [
                            '\n  (',
                            this.error.status ? [
                                _createElement('b', { 'key': '36550' }, 'status:'),
                                ' ',
                                this.error.status,
                                ', '
                            ] : null,
                            this.error.userId ? [
                                _createElement('b', { 'key': '37420' }, 'id:'),
                                ' ',
                                this.error.userId,
                                ', '
                            ] : null,
                            this.error.timestamp ? [
                                _createElement('b', { 'key': '38250' }, 'timestamp:'),
                                ' ',
                                this.error.timestamp,
                                ', '
                            ] : null,
                            _createElement('b', { 'key': '36204' }, 'instance:'),
                            ' ',
                            this.error.instance,
                            ')'
                        ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.retry
                        }, 'try your search again'), '.\n') : null);
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })], [this.loadMore(function () {
                        return _createElement('div', { 'className': 'cm_loadMore' }, _createElement('div', { 'className': 'cm_load-more' }, this.isNotAllItemsReceived ? [
                            this.isActive ? [this.loading] : null,
                            !this.isActive ? [[this.loadMoreButton(function () {
                                        return _createElement('div', {
                                            'className': 'btn btn-default btn-inverse btn btn-default cm_loadMoreButton',
                                            'onClick': this.activate
                                        }, '\n        ', window.Convermax.config?.loadMoreText || `Next ${ this.nextPageSize }`, '\n      ');
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null
                        ] : null));
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]);
            }, {
                widgetName: 'results',
                items: undefined
            })])]), _createElement('div', { 'className': 'clear' }));
}
        export const componentNames = ["cm:filterChips","cm:tooltip","cm:filterInput","cm:inputMin","cm:inputMax","cm:Inputs","cm:WidthFacet","cm:LengthFacet","cm:facets","cm:FacetPanel","cm:message","cm:customMessage","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:message","cm:loadMoreButton","cm:loadMore","cm:SearchResult"]