
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function repeatI1(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function scopePrice3() {
                    var price = this.min_price.toLocaleString('en-US', { minimumFractionDigits: 2 });
                    return _createElement('div', {
                        'className': 'cm_product-price',
                        'key': '776'
                    }, '\n    From $', price.split('.')[0], _createElement('sup', {}, price.split('.')[1]));
                }
                return _createElement('a', {
                    'href': this.url,
                    'className': 'cm_product-item'
                }, _createElement('div', { 'className': 'img-block' }, _createElement('img', {
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                })), this.Badge ? _createElement('div', {
                    'className': 'cm_badge',
                    'key': '218'
                }, this.Badge[0]) : null, [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '2830'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI1.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], _createElement('div', mergeProps({ 'className': 'cm_product-title' }, { dangerouslySetInnerHTML: { __html: this.title } })), !isNaN(this.min_price) ? scopePrice3.apply(this, []) : null, this.on_sale ? _createElement('span', {
                    'className': 'sale-tag',
                    'key': '1038'
                }, '\n    Save ', Math.round((this.compare_at_price - this.min_price) / this.compare_at_price * 100), '%\n  ') : null);
            }, { count: undefined })];
    }
    return _createElement('div', {}, _createElement('h2', {}, 'You might also like...'), _createElement.apply(this, [
        'div',
        { 'className': 'cm_carousel cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]), _createElement('div', { 'className': 'cm_browse-coordinating' }, '\n    Browse all\n    ', _createElement('a', {
        'href': this.searchLink,
        'target': '_blank'
    }, '\n      similar rugs\n      ', _createElement('i', {
        'className': 'fa fa-caret-right',
        'aria-hidden': 'true'
    }))));
}
        export const componentNames = []