
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedColorsRT () {
    return _createElement('div', {}, '\n  Colors in this product include: ', this.colorTiles, '\n  ', _createElement('a', {
        'href': this.searchLink,
        'target': '_blank'
    }, '\n    More Like This\n    ', _createElement('i', {
        'className': 'fa fa-caret-right',
        'aria-hidden': 'true'
    })));
}
        export const componentNames = []