
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function FacetDialogRT () {
    return _createElement('div', { 'className': 'dialog-container' }, _createElement('div', { 'className': 'dialog-content' }, _createElement('div', { 'className': 'dialog-header' }, _createElement('div', { 'className': 'dialog-title-container' }, _createElement('div', { 'className': 'dialog-title' }, 'Filters'), _createElement('div', { 'className': 'total-hits' }, this.totalHits, ' Results')), _createElement('div', { 'className': 'buttons-container' }, _createElement('div', {
        'className': 'cm_button cm_facet-dialog_button-clear btn secondary',
        'onClick': this.clear
    }, '\n          Clear\n        '), _createElement('div', {
        'className': 'cm_button cm_facet-dialog_button-done btn btn-default',
        'onClick': this.close
    }, '\n          Done\n        '))), this.withColorSearch ? [_createElement('div', {
            'className': 'cm_FacetPanel cm_facet-panel__full-width dialog-body',
            'key': '5931'
        }, [[this.filterChips(function () {
                    function repeatChips1(chips, chipsIndex) {
                        return [chips(function () {
                                function repeatI1_reviewChip(i, iIndex) {
                                    return _createElement('span', {
                                        'key': `${ this.field }|${ i }`,
                                        'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                    });
                                }
                                function scopeDollarInchMm1_rangeChip() {
                                    var dollar = [
                                        'price',
                                        'Price'
                                    ].includes(this.field) ? '$' : '';
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '484' }, '\n    ', dollar, this.selection[0], inch, mm, ' to ', dollar, this.selection[1], inch, mm, '\n  ') : null, this.toPosInf ? _createElement(_Fragment, { 'key': '626' }, ' ', dollar, this.selection[0], inch, mm, ' and more ') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '732' }, ' ', dollar, this.selection[1], inch, mm, ' and less ') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'image',
                                            'viewBox': '0 0 22 22',
                                            'key': '8680'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                }
                                return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'image',
                                        'viewBox': '0 0 22 22',
                                        'key': '2570'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                    'a',
                                    {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    },
                                    _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                    _map([
                                        0,
                                        1,
                                        2,
                                        3,
                                        4
                                    ], repeatI1_reviewChip.bind(this)),
                                    this.value !== '5' ? _createElement('span', { 'key': '391' }, ' and up') : null
                                ]), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'image',
                                        'viewBox': '0 0 22 22',
                                        'key': '4900'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeDollarInchMm1_rangeChip.apply(this, []) : this.template === 'colorChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_colorChip' }, _createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), '\n  ', this.value, '\n  ', _createElement('span', { 'className': 'cm_chip-color' }, this.colorTile, ' ', this.percent, '%')), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'image',
                                        'viewBox': '0 0 22 22',
                                        'key': '2640'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                        'className': 'facettitle',
                        'tabIndex': '0'
                    }, 'Your search:'), _createElement('div', {
                        'className': 'facetbody',
                        'role': 'list'
                    }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_chips' },
                        _map(this.chips, repeatChips1.bind(this))
                    ]), _createElement('div', {
                        'className': 'button btn btn-default cm_filter-chips_button',
                        'data-cm-role': 'start-over'
                    }, '\n        Clear all\n      '))));
                }, {
                    widgetName: 'filter-chips',
                    items: undefined
                })]], [this.facets(function () {
                function repeatFacets1(facets, facetsIndex) {
                    return [facets(function () {
                            function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                return [ShowAlwaysValues(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass + ' with-checkbox' }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'custom cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            function repeatValues2(Values, ValuesIndex) {
                                return [Values(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass + ' with-checkbox' }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'custom cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            function repeatShowAlwaysValues3(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                return [ShowAlwaysValues(function () {
                                        function scopeStarFill1(i, iIndex) {
                                            var starFill = this.value - i;
                                            return _createElement('span', {
                                                'key': i,
                                                'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                            });
                                        }
                                        function repeatI2(i, iIndex, starFill) {
                                            return scopeStarFill1.apply(this, [
                                                i,
                                                iIndex
                                            ]);
                                        }
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass + ' with-checkbox' }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'custom cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, _createElement.apply(this, [
                                            'span',
                                            { 'className': 'cm_review-stars' },
                                            _map([
                                                0,
                                                1,
                                                2,
                                                3,
                                                4
                                            ], repeatI2.bind(this))
                                        ]), this.value !== '5' ? _createElement('span', {
                                            'className': 'cm_and-up',
                                            'key': '518'
                                        }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            function repeatValues4(Values, ValuesIndex) {
                                return [Values(function () {
                                        function scopeStarFill1(i, iIndex) {
                                            var starFill = this.value - i;
                                            return _createElement('span', {
                                                'key': i,
                                                'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                            });
                                        }
                                        function repeatI2(i, iIndex, starFill) {
                                            return scopeStarFill1.apply(this, [
                                                i,
                                                iIndex
                                            ]);
                                        }
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass + ' with-checkbox' }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'custom cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, _createElement.apply(this, [
                                            'span',
                                            { 'className': 'cm_review-stars' },
                                            _map([
                                                0,
                                                1,
                                                2,
                                                3,
                                                4
                                            ], repeatI2.bind(this))
                                        ]), this.value !== '5' ? _createElement('span', {
                                            'className': 'cm_and-up',
                                            'key': '518'
                                        }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            function repeatValues5(Values, ValuesIndex) {
                                return [Values(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass + ' with-checkbox' }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'custom cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, !this.isExtraRange ? _createElement('span', { 'key': '236' }, this.value) : null, this.isExtraRange && this.finite ? _createElement('span', { 'key': '297' }, '$', this.range[0], ' - $', this.range[1]) : null, this.isExtraRange && this.toPosInf ? _createElement('span', { 'key': '413' }, '$', this.range[0], ' and more') : null, this.isExtraRange && this.toNegInf ? _createElement('span', { 'key': '516' }, '$', this.range[1], ' and less') : null), !this.isExtraRange ? _createElement('div', {
                                            'className': 'facetentryval',
                                            'key': '628'
                                        }, this.hitCount) : null);
                                    }, { count: undefined })];
                            }
                            return _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                                'className': 'facettitle',
                                'data-cm-role': 'toggle-facet',
                                'tabIndex': '0'
                            }, '\n      ', this.name, '\n      ', [this.tooltip(function () {
                                    return _createElement('span', { 'className': 'cm_tooltip' }, _createElement('i', {
                                        'className': 'fa fa-question-circle-o',
                                        'aria-hidden': true
                                    }));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })], this.isCollapsed ? [_createElement('svg', {
                                    'className': 'cm_icon cm_icon-plus-minus',
                                    'height': '20px',
                                    'role': 'img',
                                    'viewBox': '0 0 448 512',
                                    'key': '4640'
                                }, _createElement('path', { 'd': 'M416,208H272V64c0-17.67-14.33-32-32-32h-32c-17.67,0-32,14.33-32,32v144H32c-17.67,0-32,14.33-32,32v32c0,17.67,14.33,32,32,32h144v144c0,17.67,14.33,32,32,32h32c17.67,0,32-14.33,32-32V304h144c17.67,0,32-14.33,32-32v-32c0-17.67-14.33-32-32-32z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                    'className': 'cm_icon cm_icon-plus-minus',
                                    'height': '20px',
                                    'role': 'img',
                                    'viewBox': '0 0 448 512',
                                    'key': '8680'
                                }, _createElement('path', { 'd': 'M416,208H32c-17.67,0-32,14.33-32,32v32c0,17.67,14.33,32,32,32h384c17.67,0,32-14.33,32-32v-32c0-17.67-14.33-32-32-32z' }))] : null), _createElement('div', {
                                'className': 'facetbody',
                                'role': 'list'
                            }, this.template === 'simpleFacet' ? _createElement('div', {
                                'className': 'cmTemplate_simpleFacet',
                                'key': '1203'
                            }, this.showFilterInput ? _createElement('div', {
                                'className': 'filter-input',
                                'key': '1289'
                            }, [this.filterInput(function () {
                                    return _createElement('div', {
                                        'className': 'input  cm_filterInput',
                                        'placeholder': 'Enter'
                                    });
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })], this.inputNotEmpty ? _createElement('span', {
                                'className': 'filter-input_clear-container',
                                'onClick': this.clearInput,
                                'key': '1609'
                            }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                    'className': 'facetdiv',
                                    'key': '17781'
                                }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_ShowAlwaysValues' },
                                _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                            ]), _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_Values' },
                                _map(this.Values, repeatValues2.bind(this))
                            ]), this.needShowMore ? _createElement('div', {
                                'className': 'facetdiv cm_show-all-container',
                                'key': '3677'
                            }, _createElement('a', {
                                'className': 'cm_show-all',
                                'data-cm-role': 'toggle-show-more',
                                'tabIndex': '0'
                            }, this.moreValuesToShow ? ['Show all'] : null, !this.moreValuesToShow ? ['Show only top'] : null)) : null)) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                'className': 'cmTemplate_reviewFacet',
                                'key': '4006'
                            }, _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_ShowAlwaysValues' },
                                _map(this.ShowAlwaysValues, repeatShowAlwaysValues3.bind(this))
                            ]), _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_Values' },
                                _map(this.Values, repeatValues4.bind(this))
                            ])) : null, this.template === 'priceFacet' ? _createElement('div', {
                                'className': 'cmTemplate_priceFacet',
                                'key': '7193'
                            }, !this.ranges.length ? [
                                _createElement('div', { 'key': '72771' }, '$', this.selection[0], ' to $', this.selection[1]),
                                '\n  ',
                                this.slider,
                                '\n  ',
                                _createElement('div', {
                                    'className': 'cm_flex',
                                    'key': '72773'
                                }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: $', this.min), _createElement('div', {}, 'Max: $', this.max))
                            ] : null, this.ranges.length ? [_createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cmRepeater_Values',
                                        'key': '75451'
                                    },
                                    _map(this.Values, repeatValues5.bind(this))
                                ])] : null, [this.Inputs(function () {
                                    return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                            return _createElement('div', {
                                                'placeholder': 'Min',
                                                'className': 'cm_inputMin'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], _createElement('span', { 'className': 'separator' }, 'to'), [this.inputMax(function () {
                                            return _createElement('div', {
                                                'placeholder': 'Max',
                                                'className': 'cm_inputMax'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], _createElement('button', {
                                        'type': 'button',
                                        'className': 'button btn secondary',
                                        'data-cm-role': 'add-facet'
                                    }, '\n    GO\n  '));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]) : null, this.template === 'thicknessFacet' ? _createElement('div', {
                                'className': 'cmTemplate_thicknessFacet',
                                'key': '10085'
                            }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, 'Selected: ', this.selection[0], '" to ', this.selection[1], '"'), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min), _createElement('div', { 'className': 'max' }, this.max), _createElement('div', { 'className': 'clear' })))) : null, this.template === 'advancedColorFacet' ? _createElement('div', {
                                'className': 'cmTemplate_advancedColorFacet',
                                'key': '10484'
                            }, _createElement('div', { 'className': 'base-palette' }, '\n  ', this.paletteItems, '\n  ', _createElement('div', { 'className': 'clear' }), _createElement('div', {
                                'className': 'cm_button',
                                'onClick': this.openSimplePaletteDialog
                            }, _createElement('i', {
                                'className': 'fa fa-picture-o',
                                'aria-hidden': 'true'
                            }), '\n    Pick your colors\n  '), _createElement('div', {
                                'className': 'cm_button',
                                'onClick': this.openColorExtractorDialog
                            }, _createElement('i', {
                                'className': 'fa fa-cloud-upload',
                                'aria-hidden': 'true'
                            }), '\n    Upload Your Color\n  '), _createElement('div', { 'className': 'clear' })), '\n', this.sliders, '\n') : null, this.template === 'sizeFacet' ? _createElement('div', {
                                'className': 'cmTemplate_sizeFacet',
                                'key': '11020'
                            }, [this.WidthFacet(function () {
                                    return _createElement('div', { 'className': 'cm_WidthFacet' }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, _createElement('span', { 'className': 'subsize-title' }, this.name, ': '), !this.isNullRange ? _createElement('span', { 'key': '142' }, this.selection[0], ' - ', this.selection[1]) : null, this.isNullRange ? _createElement('span', { 'key': '227' }, this.selection[0]) : null), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min), _createElement('div', { 'className': 'max' }, this.max), _createElement('div', { 'className': 'clear' }))));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })], [this.LengthFacet(function () {
                                    return _createElement('div', { 'className': 'cm_LengthFacet' }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, _createElement('span', { 'className': 'subsize-title' }, this.name, ': '), !this.isNullRange ? _createElement('span', { 'key': '143' }, this.selection[0], ' - ', this.selection[1]) : null, this.isNullRange ? _createElement('span', { 'key': '228' }, this.selection[0]) : null), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min), _createElement('div', { 'className': 'max' }, this.max), _createElement('div', { 'className': 'clear' }))));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]) : null));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cmRepeater_facets' },
                    _map(this.facets, repeatFacets1.bind(this))
                ]));
            }, {
                widgetName: 'undefined',
                items: undefined
            })])] : null, !this.withColorSearch ? [_createElement('div', {
            'className': 'cm_FacetPanel cm_facet-panel__full-width dialog-body',
            'key': '252651'
        }, [[this.filterChips(function () {
                    function repeatChips1(chips, chipsIndex) {
                        return [chips(function () {
                                function repeatI1_reviewChip(i, iIndex) {
                                    return _createElement('span', {
                                        'key': `${ this.field }|${ i }`,
                                        'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                    });
                                }
                                function scopeDollarInchMm1_rangeChip() {
                                    var dollar = [
                                        'price',
                                        'Price'
                                    ].includes(this.field) ? '$' : '';
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '484' }, '\n    ', dollar, this.selection[0], inch, mm, ' to ', dollar, this.selection[1], inch, mm, '\n  ') : null, this.toPosInf ? _createElement(_Fragment, { 'key': '626' }, ' ', dollar, this.selection[0], inch, mm, ' and more ') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '732' }, ' ', dollar, this.selection[1], inch, mm, ' and less ') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'image',
                                            'viewBox': '0 0 22 22',
                                            'key': '8680'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                }
                                return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'image',
                                        'viewBox': '0 0 22 22',
                                        'key': '2570'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                    'a',
                                    {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    },
                                    _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                    _map([
                                        0,
                                        1,
                                        2,
                                        3,
                                        4
                                    ], repeatI1_reviewChip.bind(this)),
                                    this.value !== '5' ? _createElement('span', { 'key': '391' }, ' and up') : null
                                ]), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'image',
                                        'viewBox': '0 0 22 22',
                                        'key': '4900'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeDollarInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                        'className': 'facettitle',
                        'tabIndex': '0'
                    }, 'Your search:'), _createElement('div', {
                        'className': 'facetbody',
                        'role': 'list'
                    }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_chips' },
                        _map(this.chips, repeatChips1.bind(this))
                    ]), _createElement('div', {
                        'className': 'button btn secondary cm_filter-chips_button',
                        'data-cm-role': 'start-over'
                    }, '\n        Clear all\n      '))));
                }, {
                    widgetName: 'filter-chips',
                    items: undefined
                })]], [this.facets(function () {
                function repeatFacets1(facets, facetsIndex) {
                    return [facets(function () {
                            function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                return [ShowAlwaysValues(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass + ' with-checkbox' }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'custom cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            function repeatValues2(Values, ValuesIndex) {
                                return [Values(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass + ' with-checkbox' }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'custom cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            function repeatShowAlwaysValues3(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                return [ShowAlwaysValues(function () {
                                        function scopeStarFill1(i, iIndex) {
                                            var starFill = this.value - i;
                                            return _createElement('span', {
                                                'key': i,
                                                'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                            });
                                        }
                                        function repeatI2(i, iIndex, starFill) {
                                            return scopeStarFill1.apply(this, [
                                                i,
                                                iIndex
                                            ]);
                                        }
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass + ' with-checkbox' }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'custom cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, _createElement.apply(this, [
                                            'span',
                                            { 'className': 'cm_review-stars' },
                                            _map([
                                                0,
                                                1,
                                                2,
                                                3,
                                                4
                                            ], repeatI2.bind(this))
                                        ]), this.value !== '5' ? _createElement('span', {
                                            'className': 'cm_and-up',
                                            'key': '518'
                                        }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            function repeatValues4(Values, ValuesIndex) {
                                return [Values(function () {
                                        function scopeStarFill1(i, iIndex) {
                                            var starFill = this.value - i;
                                            return _createElement('span', {
                                                'key': i,
                                                'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                            });
                                        }
                                        function repeatI2(i, iIndex, starFill) {
                                            return scopeStarFill1.apply(this, [
                                                i,
                                                iIndex
                                            ]);
                                        }
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass + ' with-checkbox' }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'custom cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, _createElement.apply(this, [
                                            'span',
                                            { 'className': 'cm_review-stars' },
                                            _map([
                                                0,
                                                1,
                                                2,
                                                3,
                                                4
                                            ], repeatI2.bind(this))
                                        ]), this.value !== '5' ? _createElement('span', {
                                            'className': 'cm_and-up',
                                            'key': '518'
                                        }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            function repeatValues5(Values, ValuesIndex) {
                                return [Values(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass + ' with-checkbox' }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'custom cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, !this.isExtraRange ? _createElement('span', { 'key': '236' }, this.value) : null, this.isExtraRange && this.finite ? _createElement('span', { 'key': '297' }, '$', this.range[0], ' - $', this.range[1]) : null, this.isExtraRange && this.toPosInf ? _createElement('span', { 'key': '413' }, '$', this.range[0], ' and more') : null, this.isExtraRange && this.toNegInf ? _createElement('span', { 'key': '516' }, '$', this.range[1], ' and less') : null), !this.isExtraRange ? _createElement('div', {
                                            'className': 'facetentryval',
                                            'key': '628'
                                        }, this.hitCount) : null);
                                    }, { count: undefined })];
                            }
                            return _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                                'className': 'facettitle',
                                'data-cm-role': 'toggle-facet',
                                'tabIndex': '0'
                            }, '\n      ', this.name, '\n      ', [this.tooltip(function () {
                                    return _createElement('span', { 'className': 'cm_tooltip' }, _createElement('i', {
                                        'className': 'fa fa-question-circle-o',
                                        'aria-hidden': true
                                    }));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })], this.isCollapsed ? [_createElement('svg', {
                                    'className': 'cm_icon cm_icon-plus-minus',
                                    'height': '20px',
                                    'role': 'img',
                                    'viewBox': '0 0 448 512',
                                    'key': '4640'
                                }, _createElement('path', { 'd': 'M416,208H272V64c0-17.67-14.33-32-32-32h-32c-17.67,0-32,14.33-32,32v144H32c-17.67,0-32,14.33-32,32v32c0,17.67,14.33,32,32,32h144v144c0,17.67,14.33,32,32,32h32c17.67,0,32-14.33,32-32V304h144c17.67,0,32-14.33,32-32v-32c0-17.67-14.33-32-32-32z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                    'className': 'cm_icon cm_icon-plus-minus',
                                    'height': '20px',
                                    'role': 'img',
                                    'viewBox': '0 0 448 512',
                                    'key': '8680'
                                }, _createElement('path', { 'd': 'M416,208H32c-17.67,0-32,14.33-32,32v32c0,17.67,14.33,32,32,32h384c17.67,0,32-14.33,32-32v-32c0-17.67-14.33-32-32-32z' }))] : null), _createElement('div', {
                                'className': 'facetbody',
                                'role': 'list'
                            }, this.template === 'simpleFacet' ? _createElement('div', {
                                'className': 'cmTemplate_simpleFacet',
                                'key': '1203'
                            }, this.showFilterInput ? _createElement('div', {
                                'className': 'filter-input',
                                'key': '1289'
                            }, [this.filterInput(function () {
                                    return _createElement('div', {
                                        'className': 'input  cm_filterInput',
                                        'placeholder': 'Enter'
                                    });
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })], this.inputNotEmpty ? _createElement('span', {
                                'className': 'filter-input_clear-container',
                                'onClick': this.clearInput,
                                'key': '1609'
                            }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                    'className': 'facetdiv',
                                    'key': '17781'
                                }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_ShowAlwaysValues' },
                                _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                            ]), _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_Values' },
                                _map(this.Values, repeatValues2.bind(this))
                            ]), this.needShowMore ? _createElement('div', {
                                'className': 'facetdiv cm_show-all-container',
                                'key': '3677'
                            }, _createElement('a', {
                                'className': 'cm_show-all',
                                'data-cm-role': 'toggle-show-more',
                                'tabIndex': '0'
                            }, this.moreValuesToShow ? ['Show all'] : null, !this.moreValuesToShow ? ['Show only top'] : null)) : null)) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                'className': 'cmTemplate_reviewFacet',
                                'key': '4006'
                            }, _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_ShowAlwaysValues' },
                                _map(this.ShowAlwaysValues, repeatShowAlwaysValues3.bind(this))
                            ]), _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_Values' },
                                _map(this.Values, repeatValues4.bind(this))
                            ])) : null, this.template === 'priceFacet' ? _createElement('div', {
                                'className': 'cmTemplate_priceFacet',
                                'key': '7193'
                            }, !this.ranges.length ? [
                                _createElement('div', { 'key': '72771' }, '$', this.selection[0], ' to $', this.selection[1]),
                                '\n  ',
                                this.slider,
                                '\n  ',
                                _createElement('div', {
                                    'className': 'cm_flex',
                                    'key': '72773'
                                }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: $', this.min), _createElement('div', {}, 'Max: $', this.max))
                            ] : null, this.ranges.length ? [_createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cmRepeater_Values',
                                        'key': '75451'
                                    },
                                    _map(this.Values, repeatValues5.bind(this))
                                ])] : null, [this.Inputs(function () {
                                    return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                            return _createElement('div', {
                                                'placeholder': 'Min',
                                                'className': 'cm_inputMin'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], _createElement('span', { 'className': 'separator' }, 'to'), [this.inputMax(function () {
                                            return _createElement('div', {
                                                'placeholder': 'Max',
                                                'className': 'cm_inputMax'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], _createElement('button', {
                                        'type': 'button',
                                        'className': 'button btn secondary',
                                        'data-cm-role': 'add-facet'
                                    }, '\n    GO\n  '));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]) : null, this.template === 'thicknessFacet' ? _createElement('div', {
                                'className': 'cmTemplate_thicknessFacet',
                                'key': '10085'
                            }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, 'Selected: ', this.selection[0], '" to ', this.selection[1], '"'), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min), _createElement('div', { 'className': 'max' }, this.max), _createElement('div', { 'className': 'clear' })))) : null, this.template === 'advancedColorFacet' ? _createElement('div', {
                                'className': 'cmTemplate_advancedColorFacet',
                                'key': '10484'
                            }, _createElement('div', { 'className': 'base-palette' }, '\n  ', this.paletteItems, '\n  ', _createElement('div', { 'className': 'clear' }), _createElement('div', {
                                'className': 'cm_button',
                                'onClick': this.openSimplePaletteDialog
                            }, _createElement('i', {
                                'className': 'fa fa-picture-o',
                                'aria-hidden': 'true'
                            }), '\n    Pick your colors\n  '), _createElement('div', {
                                'className': 'cm_button',
                                'onClick': this.openColorExtractorDialog
                            }, _createElement('i', {
                                'className': 'fa fa-cloud-upload',
                                'aria-hidden': 'true'
                            }), '\n    Upload Your Color\n  '), _createElement('div', { 'className': 'clear' })), '\n', this.sliders, '\n') : null, this.template === 'sizeFacet' ? _createElement('div', {
                                'className': 'cmTemplate_sizeFacet',
                                'key': '11020'
                            }, [this.WidthFacet(function () {
                                    return _createElement('div', { 'className': 'cm_WidthFacet' }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, _createElement('span', { 'className': 'subsize-title' }, this.name, ': '), !this.isNullRange ? _createElement('span', { 'key': '142' }, this.selection[0], ' - ', this.selection[1]) : null, this.isNullRange ? _createElement('span', { 'key': '227' }, this.selection[0]) : null), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min), _createElement('div', { 'className': 'max' }, this.max), _createElement('div', { 'className': 'clear' }))));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })], [this.LengthFacet(function () {
                                    return _createElement('div', { 'className': 'cm_LengthFacet' }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, _createElement('span', { 'className': 'subsize-title' }, this.name, ': '), !this.isNullRange ? _createElement('span', { 'key': '143' }, this.selection[0], ' - ', this.selection[1]) : null, this.isNullRange ? _createElement('span', { 'key': '228' }, this.selection[0]) : null), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min), _createElement('div', { 'className': 'max' }, this.max), _createElement('div', { 'className': 'clear' }))));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]) : null));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cmRepeater_facets' },
                    _map(this.facets, repeatFacets1.bind(this))
                ]));
            }, {
                widgetName: 'undefined',
                items: undefined
            })])] : null));
}
        export const componentNames = ["cm:filterChips","cm:tooltip","cm:filterInput","cm:inputMin","cm:inputMax","cm:Inputs","cm:WidthFacet","cm:LengthFacet","cm:facets","cm:filterChips","cm:tooltip","cm:filterInput","cm:inputMin","cm:inputMax","cm:Inputs","cm:WidthFacet","cm:LengthFacet","cm:facets"]