const paletteColors = [
  ['Brown', '4f2907'],
  ['Pink', 'eb6fa4'],
  ['Peacock', '008080'],
  ['Red', 'c00718'],
  ['Gray', '808080'],
  ['Blue', '1f5e9e'],
  ['Tan', 'dac8a0'],
  ['Yellow', 'ffd246'],
  ['Purple', '67357e'],
  ['Light Blue', '90c1e4'],
  ['Peach', 'fbbd93'],
  ['Black', '000000'],
  ['Green', '248a0f'],
  ['Burgundy', '750f17'],
  ['Orange', 'fa760a'],
  ['Gainsboro', 'dfdddd'],
  ['Pearl', 'f0ead6'],
  ['Ivory', 'fffff0'],
];

/* globals cm$ */
function InitFunc() {
  window.Convermax.header = {
    title: getInHeaderHtml('.section-header--title'),
    content: getInHeaderHtml('.rte--header'),
  };
  function getInHeaderHtml(selector) {
    const inHeaderSelector = `header.section-header ${selector}`;
    const elem = document.querySelector(inHeaderSelector);
    return elem && elem.innerHTML;
  }
}

function onRelatedItemsRendered() {
  if (!cm$ || cm$('.cm_carousel.slick-slider').length) {
    return;
  }
  const slidesCount = window.width > 998 ? 6 : window.width > 767 ? 4 : window.width > 320 ? 2 : 1;
  cm$('.cm_carousel').slick({
    infinite: false,
    slidesToShow: slidesCount,
    slidesToScroll: slidesCount,
    prevArrow: '<div class="cm_prev"></div>',
    nextArrow: '<div class="cm_next"></div>',
  });
}

const { product: { available: isProductAvailable } = {} } = window.__CONVERMAX__ || {};

function getRelatedItemsSelection() {
  // eslint-disable-next-line no-undef
  const { product: { tags } = {} } = __CONVERMAX__;

  if (!tags) {
    return null;
  }

  const prefix = 'Styles_';
  const tagSelection = tags
    .filter((t) => t.startsWith(prefix))
    .map((t) => ({ term: t.replace(prefix, ''), field: 'Styles' }));

  return tagSelection;
}

export default {
  platform: 'shopify',
  searchPageUrl: '/search',
  InitFunc,
  stickyHeaderSelector: '.privy-tab',
  product: {
    // eslint-disable-next-line no-undef
    getImageToExtractColors: () => (__CONVERMAX__.product || {}).image,
    noImageSrc:
      'https://cdn.shopify.com/s/assets/no-image-2048-5e88c1b20e087fb7bbe9a3771824e743c244f437e4f8ba93bbf7b11b53f7824c.gif',
    searchResultsImageHeight: 240,
  },
  ColorEngineFeature: true,
  SearchRequestDefaults: {
    pageSize: 48,
  },
  requestTimeout: 25000,
  facets: {
    advancedColorFacet: { fields: ['_Color'], paletteColors },
    sizeFacet: {
      fields: ['Size'],
      step: 'imperial',
      childFields: ['Width', 'Length'],
      singleFacet: true,
    },
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['Thickness'], name: 'thicknessFacet', step: '1/100' },
    ],
  },
  Widgets: [
    {
      name: 'search-container',
      type: 'SearchPage',
      location: {
        replace: 'body.template-search #cm_search-container',
      },
      template: 'colorSearch/searchPage',
    },
    {
      name: 'CollectionContainer',
      type: 'SearchPage',
      location: {
        replace: 'body.template-collection .grid.grid-border',
      },
      template: 'CollectionPage',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.search-bar',
        class: 'search-bar',
        wrapper: 'span',
      },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'product-colors',
      type: 'RelatedColors',
      location: {
        replace: '#cm_product-colors',
        class: 'small--hide cm_product-colors',
      },
      template: 'product/relatedColors',
    },
    {
      name: 'coordinating-items',
      // actually used only for getSearchLink function
      type: 'ProductData',
      location: {
        replace: '#cm_coordinating-items',
      },
      template: 'product/productData',
      visibleIf: () => isProductAvailable,
    },
    {
      name: 'might-also-like',
      type: 'RelatedItems',
      location: {
        replace: '#cm_might-also-like',
      },
      template: 'product/relatedItems',
      count: 20,
      getSelection: getRelatedItemsSelection,
      onItemsRendered: onRelatedItemsRendered,
      visibleIf: () => isProductAvailable,
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        '_popularity:desc': 'Popularity',
        'review_score:desc': 'Customer Review',
        'price': 'Price: Low to High',
        'price:desc': 'Price: High to Low',
        'published_at:desc': 'Date: Newest to Oldest',
      },
    },
    {
      name: 'SearchResult',
      instantInfiniteScroll: true,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'SimpleColorPaletteDialog',
      field: '_Color',
      template: 'colorSearch/simplePaletteDialog',
    },
    {
      name: 'ColorExtractorDialog',
      field: '_Color',
      template: 'colorSearch/colorExtractorDialog',
    },
  ],
};
