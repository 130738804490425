import { SearchPage } from 'Components'
import TemplatecolorSearchsearchPage from 'Stores/_default-store/Templates/colorSearch/searchPage.rt'
import TemplateCollectionPage from 'Stores/incredible-rugs-and-decor/Templates/CollectionPage.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import { RelatedColors } from 'Components'
import TemplateproductrelatedColors from 'Stores/incredible-rugs-and-decor/Templates/product/relatedColors.rt'
import { ProductData } from 'Components'
import TemplateproductproductData from 'Stores/incredible-rugs-and-decor/Templates/product/productData.rt'
import { RelatedItems } from 'Components'
import TemplateproductrelatedItems from 'Stores/incredible-rugs-and-decor/Templates/product/relatedItems.rt'
import { FacetPanel } from 'Components'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { SimpleColorPaletteDialog } from 'Components'
import TemplatecolorSearchsimplePaletteDialog from 'Stores/_default-store/Templates/colorSearch/simplePaletteDialog.rt'
import { ColorExtractorDialog } from 'Components'
import TemplatecolorSearchcolorExtractorDialog from 'Stores/_default-store/Templates/colorSearch/colorExtractorDialog.rt'

export const compMap = {
  SearchPage,
SearchBoxDialogButton,
RelatedColors,
ProductData,
RelatedItems,
FacetPanel,
SearchHeader,
SearchResult,
FacetDialog,
SearchBoxDialog,
SimpleColorPaletteDialog,
ColorExtractorDialog
} 
export const tplMap = {
  'colorSearch/searchPage': TemplatecolorSearchsearchPage,
'CollectionPage': TemplateCollectionPage,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'product/relatedColors': TemplateproductrelatedColors,
'product/productData': TemplateproductproductData,
'product/relatedItems': TemplateproductrelatedItems,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog,
'colorSearch/simplePaletteDialog': TemplatecolorSearchsimplePaletteDialog,
'colorSearch/colorExtractorDialog': TemplatecolorSearchcolorExtractorDialog
};