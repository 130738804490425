//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-732:_3728,_8392,_6864,_9352,_2970,_2880,_4616,_7112;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-732')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-732', "_3728,_8392,_6864,_9352,_2970,_2880,_4616,_7112");
        }
      }catch (ex) {
        console.error(ex);
      }